import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-fr';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/datepicker.css';

const lang = $('html').attr('lang').slice(0, 2);

const viewport = $(window).width();

$('#datepicker-start').datepicker({
  regional: lang,
  showOn: 'both',
  buttonText: '',
  buttonImage: 'theme/styles/assets/icons/calendar-today.png',
  buttonImageOnly: false,
  defaultDate: '+1w',
  // Prevent calendar too large on mobile
  numberOfMonths: viewport < 768 ? 1 : 2,
  minDate: 0,
  maxDate: '1Y',
  dateFormat: 'dd/mm/yy',
  beforeShowDay: highlightDates,
  onClose(selectedDate) {
    $('#datepicker-end').datepicker('option', 'minDate', selectedDate);
    $('#datepicker-end').focus();
  },
});

$('#datepicker-end').datepicker({
  beforeShowDay: highlightDates,
  regional: lang,
  showOn: 'both',
  buttonText: '',
  buttonImage: 'theme/styles/assets/icons/calendar-today.png',
  buttonImageOnly: false,
  defaultDate: '+1w',
  maxDate: '1Y',
  // Prevent calendar too large on mobile
  numberOfMonths: viewport < 768 ? 1 : 2,
  dateFormat: 'dd/mm/yy',
});


/*$('#datepicker-start2').datepicker({
  regional: lang,
  showOn: 'both',
  buttonText: '',
  buttonImage: 'theme/styles/assets/icons/calendar-today.png',
  buttonImageOnly: false,
  //defaultDate: '+1w',
  // Prevent calendar too large on mobile
  numberOfMonths: viewport < 768 ? 1 : 2,
  minDate: 0,
  maxDate: '1Y',
  dateFormat: 'dd/mm/yy',
  beforeShowDay: highlightDates,
  onClose(selectedDate) {
    $('#datepicker-end2').datepicker('option', 'minDate', selectedDate);
    $('#datepicker-end2').focus();
  },
});

$('#datepicker-end2').datepicker({
  beforeShowDay: highlightDates,
  regional: lang,
  showOn: 'both',
  buttonText: '',
  buttonImage: 'theme/styles/assets/icons/calendar-today.png',
  buttonImageOnly: false,
  defaultDate: '+1w',
  maxDate: '1Y',
  // Prevent calendar too large on mobile
  numberOfMonths: viewport < 768 ? 1 : 2,
  dateFormat: 'dd/mm/yy',
});*/

// fonction changement date fin
$('#datepicker-start').change(() => {
  // on récupère la date de début
  const date_fin = $('#datepicker-start').datepicker('getDate');
  // on ajoute 7 jours à la date de début pour obtenir la dete de fin
  date_fin.setDate(date_fin.getDate() + 7);
  // maj date fin dans datapicker
  $('#datepicker-end').datepicker('setDate', date_fin);
});

// Fonction pour ajouter une classe aux dates entre deux dates
function highlightDates(date) {
  const startDate = $('#datepicker-start').datepicker('getDate');
  const endDate = $('#datepicker-end').datepicker('getDate');

  // Vérifier si la date est entre la date de début et la date de fin
  if (startDate && endDate) {
    const currentDate = formatDate($.datepicker.formatDate('dd/mm/yy', date));
    const formattedStartDate = formatDate($('#datepicker-start').val());
    const formattedEndDate = formatDate($('#datepicker-end').val());

    if (currentDate === formattedStartDate || currentDate === formattedEndDate) {
      return [true, 'highlighted-start-end'];
    } if (date > startDate && date < endDate) {
      return [true, 'highlighted-between', 'highlighted-between'];
    }
  }

  return [true, ''];
}

// Fonction pour formater les dates (dd/mm/yy to mm/dd/yy)
function formatDate(dateString) {
  const parts = dateString.split('/');
  return `${parts[1]}/${parts[0]}/${parts[2]}`;
}

// date de naissance & date de permis
$('.input-datepicker').each(function (index) {
  $(this).datepicker({
    defaultDate: '+1w',
    numberOfMonths: 1,
    minDate: '-90Y',
    maxDate: $(this).data('minimum'),
    changeMonth: true,
    changeYear: true,
    yearRange: '-90:+0',
    showOn: 'both',
    buttonText: '',
    buttonImage: 'theme/styles/assets/icons/calendar-today.png',
    dateFormat: 'dd/mm/yy',
    buttonImageOnly: false,
  });
});


if(viewport < 768){
  $('#datepicker-start').attr('readonly', 'true');
  $('#datepicker-end').attr('readonly', 'true');
  $('.input-datepicker').attr('readonly', 'true');
}
